import type { TNAPIBody, TNAPIQuery } from '~/utils/napi-helpers'

function updateSelf(data: TNAPIBody<'/napis/v1/auth-user/self/', 'put'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/self/'
  const method = 'put'
  return openAPIFetchOnce(path, method, undefined, undefined, data)
}

function getUserProfile(userIdSlug: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/'
  const method = 'get'
  const pathParams = { requested_user_id_slug: userIdSlug }
  return openAPIFetchOnce(path, method, pathParams)
}

function updateUserProfile(
  userIdSlug: string,
  data: TNAPIBody<'/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/', 'patch'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/'
  const method = 'patch'
  const param = { requested_user_id_slug: userIdSlug }

  return openAPIFetchOnce(path, method, param, undefined, data)
}

function updateWorkExperience(
  userIdSlug: string,
  experienceSlug: string,
  data: TNAPIBody<
    '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/work-experience/{experience_slug}/',
    'patch'
  >
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path =
    '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/work-experience/{experience_slug}/'
  const method = 'patch'
  const param = { requested_user_id_slug: userIdSlug, experience_slug: experienceSlug }

  return openAPIFetchOnce(path, method, param, undefined, data)
}

function postWorkExperience(
  userIdSlug: string,
  data: TNAPIBody<
    '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/work-experience/',
    'post'
  >
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/work-experience/'
  const method = 'post'
  const param = { requested_user_id_slug: userIdSlug }

  return openAPIFetchOnce(path, method, param, undefined, data)
}

function deleteWorkExperience(userIdSlug: string, experienceSlug: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path =
    '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/work-experience/{experience_slug}/'
  const method = 'delete'
  const param = { requested_user_id_slug: userIdSlug, experience_slug: experienceSlug }

  return openAPIFetchOnce(path, method, param)
}

function updateEducation(
  userIdSlug: string,
  eduSlug: string,
  data: TNAPIBody<
    '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/educations/{edu_slug}/',
    'patch'
  >
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/educations/{edu_slug}/'
  const method = 'patch'
  const param = { requested_user_id_slug: userIdSlug, edu_slug: eduSlug }

  return openAPIFetchOnce(path, method, param, undefined, data)
}

function postEducation(
  userIdSlug: string,
  data: TNAPIBody<'/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/educations/', 'post'>
) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/educations/'
  const method = 'post'
  const param = { requested_user_id_slug: userIdSlug }

  return openAPIFetchOnce(path, method, param, undefined, data)
}

function deleteEducation(userIdSlug: string, eduSlug: string) {
  const { openAPIFetchOnce } = useAuthFetch()
  const path = '/napis/v1/auth-user/user-profiles/{requested_user_id_slug}/educations/{edu_slug}/'
  const method = 'delete'
  const param = { requested_user_id_slug: userIdSlug, edu_slug: eduSlug }

  return openAPIFetchOnce(path, method, param)
}

function searchTalents(query: TNAPIQuery<'/napis/v1/talent-search/', 'get'>) {
  const { openAPIFetchOnce } = useAuthFetch()
  return openAPIFetchOnce('/napis/v1/talent-search/', 'get', undefined, query)
}

export function useProfile() {
  return {
    updateSelf,
    getUserProfile,
    updateUserProfile,
    updateWorkExperience,
    postWorkExperience,
    deleteWorkExperience,
    updateEducation,
    postEducation,
    deleteEducation,
    searchTalents
  }
}
